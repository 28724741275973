import React, { useState, useEffect } from 'react';
import './pages_documentos.css';
import { getDatabase, ref, get } from 'firebase/database';
import firebaseApp, { KEY_LINK, KEY_REF } from '../adm/admin/firebaseConfig';
import { Box } from '@mui/material';
import TitleSection from '../utils/TittleTheme';
import DataTable from '../utils/Tabela';

export default function Pareceres() {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const databaseInstance = getDatabase(firebaseApp);
        const noticiasRef = ref(databaseInstance,  `pareceres`);

        const snapshot = await get(noticiasRef);
        const data = snapshot.val();

        if (data) {
          const newsArray = Object.keys(data).map((key) => ({
            id: key,
            title: data[key].nome,
            numero: data[key].numero || null,
            mes: data[key].mes,
            dia: data[key].dia || null,
            ano: data[key].ano,
            href: data[key].href
          }));
          setNewsData(newsArray.reverse());
        }
      } catch (error) {
        console.error('Erro ao carregar notícias:', error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Box sx={{ padding: 3, maxWidth: '1200px', mx: 'auto' }}>


      <TitleSection
        title={'PARECERES JURÍDICOS'}
        subtitle={'Os pareceres jurídicos são análises técnicas emitidas por profissionais do direito para orientar decisões administrativas e garantir conformidade legal.'}
      />


      <DataTable data={newsData} />
    </Box>
  );
}

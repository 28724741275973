import React, { useEffect, useState } from 'react';
import { query, ref, orderByKey, limitToFirst, get } from "firebase/database";import firebaseApp, { database, KEY_REF } from '../adm/admin/firebaseConfig';
import { Grid, Card, CardContent, CardMedia, Typography, Button } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';

function removePTags(html) {
    return html.replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/<ol>.*?<\/ol>/gs, '').replace(/<li>.*?<\/li>/gs, '');
}

export default function RandomNoticias({ config, quantidade }) {
    const [noticias, setNoticias] = useState([]);

    useEffect(() => {
        const fetchNoticiasAleatorias = async () => {
            if (!KEY_REF) {
                console.error("KEY_REF não definido.");
                return;
            }

            try {
                const noticiasRef = ref(database, `noticias`);

             
                // Modifique a consulta para pegar um número limitado de notícias
                const noticiasQuery = query(noticiasRef, orderByKey(), limitToFirst(8)); // Aqui você limita as 8 primeiras notícias
                const snapshot = await get(noticiasQuery);

                const data = snapshot.val();

                if (data) {
                    const noticiasArray = Object.keys(data).map((key) => ({
                        id: key,
                        data: format(parseISO(data[key].data), 'dd/MM/yyyy'),
                        titulo: data[key].titulo,
                        descricao: data[key].descricao,
                        imagem: data[key].imagem,
                    }));

                    // Ordene aleatoriamente as notícias localmente
                    const noticiasAleatorias = noticiasArray
                        .sort(() => Math.random() - 0.5)
                        .slice(0, quantidade); // Pega 6 aleatórias

                    setNoticias(noticiasAleatorias);

                    const dataSizeMB = new TextEncoder().encode(JSON.stringify(data)).length / (1024 * 1024);
                } else {
                    setNoticias([]);
               }
            } catch (error) {
                console.error("Erro ao buscar notícias aleatórias:", error);
            }
        };

        fetchNoticiasAleatorias();
    }, [KEY_REF]);



    const renderNoticias = (noticia) => {
        const truncatedDescricao = noticia.descricao.length > 120
            ? noticia.descricao.slice(0, 170) + "..."
            : noticia.descricao;
        const tituloParaURL = noticia.titulo.replace(/\s+/g, '-').toLowerCase();
        const descricaoSemPTags = removePTags(truncatedDescricao);

        return (
            <Grid item xs={12} sm={6} md={4} key={noticia.id}>
                <Card sx={{ boxShadow: 4, borderRadius: 1, overflow: 'hidden', transition: '0.4s', '&:hover': { boxShadow: 8, transform: 'scale(1.01)' }, maxWidth: 500, margin: 'auto' }}>
                    <CardMedia
                        component="img"
                        height="200"
                        image={noticia.imagem}
                        alt={noticia.titulo}
                        sx={{ objectFit: 'cover' }}
                    />
                    <CardContent sx={{ backgroundColor: '#fafafa', p: 3 }}>
                        <Typography variant="subtitle2" color="primary" sx={{ color: config?.color, textTransform: 'uppercase', fontWeight: 'bold', fontSize: 15 }}>
                            Notícias
                        </Typography>
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {noticia.titulo}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ fontStyle: 'italic', mb: 1 }}>
                            Publicado em {noticia.data}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1, color: '#333', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
                            {descricaoSemPTags}
                        </Typography>
                        <Link to={`/noticias/${tituloParaURL}/${noticia.id}`}>
                            <Button
                                variant="contained"
                                size="small"
                                startIcon={<OpenInNewIcon />}
                                sx={{ mt: 2, textTransform: 'uppercase', fontWeight: 'bold', color: '#ffffff', backgroundColor: config?.color, '&:hover': { backgroundColor: config?.colorSecondary, color: config?.color } }}
                            >
                                Continuar Lendo
                            </Button>
                        </Link>
                    </CardContent>
                </Card>
            </Grid>
        );
    };

    return (
        <Grid container spacing={3} sx={{ justifyContent: 'center', alignItems: 'flex-start' }}>
            {noticias.length > 0 ? (
                noticias.map(renderNoticias)
            ) : (
                <Typography variant="h6" align="center" sx={{ color: '#888', mt: 4 }}>
                    Não há nenhuma noticia em destaque
                </Typography>
            )}

        </Grid>
    );
}

import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { getDatabase, ref, get } from "firebase/database";
import { database, KEY_REF } from "./adm/admin/firebaseConfig";
import SecretaryPage from "./utils/SecretaryCard";
import { Container, Skeleton, Typography } from "@mui/material";

export default function ViewSecretario() {
  const [secretario, setSecretario] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  const fetchSecretario = useCallback(async () => {
    try {
      const secretarioRef = ref(database, `secretarios/${id}`);
      const snapshot = await get(secretarioRef);

      if (snapshot.exists()) {
        setSecretario(snapshot.val());
      } else {
        console.warn("Secretário não encontrado.");
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchSecretario();
  }, [fetchSecretario]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "60vh" }}>
      {isLoading ? (
        <Skeleton variant="rectangular" width={700} height={300} sx={{ borderRadius: 2 }} />
      ) : secretario ? (
        <SecretaryPage {...secretario} />
      ) : (
        <Typography variant="h6" color="textSecondary">
          Secretário não encontrado
        </Typography>
      )}
    </Container>
  );
}

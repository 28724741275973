import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import './css/ViewNoticia.css';
import { getDatabase, ref, get } from 'firebase/database';
import firebaseApp, { KEY_REF } from './adm/admin/firebaseConfig';
import SecretaryCard from '../componentes/utils/SecretaryCard';

const db = getDatabase(firebaseApp);

export default function ViewVereador() {
  const [isLoading, setIsLoading] = useState(true);
  const [noticias, setNoticias] = useState({});
  const [formattedDate, setFormattedDate] = useState('Carregando...');

  const { id } = useParams();
  const noticiasRef = ref(db, `vereadores/${id}`);

  useEffect(() => {
    const fetchNoticia = async () => {
      try {
        const snapshot = await get(noticiasRef);
        if (snapshot.exists()) {
          const noticiaData = snapshot.val();

          // Formatar a data
          const parsedDate = new Date(noticiaData.data);
          if (!isNaN(parsedDate)) {
            const formattedDate = format(parsedDate, 'dd/MM/yyyy');
            setFormattedDate(formattedDate);
          }

          setNoticias({
            nome: noticiaData.nome,
            descricao: noticiaData.descricao,
            partido: noticiaData.partido,
            imagem: noticiaData.documentoUrl,
          });
        } else {
          console.log('No data available');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNoticia();
  }, [id]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const secretario = {
    nome: noticias.nome,
    descricao: noticias.descricao,
    foto: noticias.imagem,
    imagem: noticias.imagem,
    telefone: "(83) 0000-0000",
    email: "contato@camaradegurjao.pb.gov.br",
    horario: "08h às 14h - Segunda a Sexta-Feira",
    cargo: noticias.partido,
    page: `Vereador(a) ${noticias.nome}`,
  };


  if (isLoading) {
    return <div>Carregando...</div>;
  }

  return <SecretaryCard {...secretario} />;
}

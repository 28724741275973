import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Container,
  Avatar,
  Divider,
  Tooltip,
} from "@mui/material";
import { Gavel, AccountBalance } from "@mui/icons-material";
import firebaseApp, { KEY_REF } from "../adm/admin/firebaseConfig";
import { getDatabase, ref, get } from "firebase/database";
import TitleSection from "../utils/TittleTheme";

const Vereadores = () => {
  const [vereadores, setVereadores] = useState([]);

  useEffect(() => {
    const fetchVereadores = async () => {
      try {
        if (!KEY_REF) {
          console.error("KEY_REF não definido.");
          return;
        }

        const cacheKey = `vereadores_cache_${KEY_REF}`;
        const cacheTimestampKey = `vereadores_timestamp_${KEY_REF}`;
        const cachedData = localStorage.getItem(cacheKey);
        const cacheTimestamp = localStorage.getItem(cacheTimestampKey);
        const now = Date.now();

        if (cachedData && cacheTimestamp && now - cacheTimestamp < 30 * 60 * 1000) {
          setVereadores(JSON.parse(cachedData));
          return;
        }

        const databaseInstance = getDatabase(firebaseApp);
        const vereadoresRef = ref(databaseInstance,  `vereadores`);
        const snapshot = await get(vereadoresRef);

        const data = snapshot.val();

        if (data) {
          const vereadoresArray = Object.keys(data).map((key) => ({
            id: key,
            nome: data[key].nome || "",
            partido: data[key].partido || "",
            imagem: data[key].documentoUrl || "",
            descricao: data[key].descricao || "Sem descrição disponível.",
          }));

          vereadoresArray.sort((a, b) => a.nome.localeCompare(b.nome));

          localStorage.setItem(cacheKey, JSON.stringify(vereadoresArray));
          localStorage.setItem(cacheTimestampKey, now.toString());

          setVereadores(vereadoresArray);
        } else {
          console.warn("Nenhum dado encontrado para vereadores.");
          setVereadores([]);
        }
      } catch (error) {
        console.error("Erro ao buscar os vereadores:", error);
      }
    };

    fetchVereadores();
  }, [KEY_REF]); 

  return (
    <Box sx={{ marginTop: 3 }}>
      <TitleSection
        title="NOSSOS VEREADORES"
        subtitle="Conheça os representantes que trabalham por você, conectando a comunidade às soluções e promovendo transparência no serviço público."
      />
      <Container maxWidth="lg" sx={{ paddingX: { xs: 2, md: 2 }, marginY: 3 }}>
        <Grid container spacing={3}>
          {vereadores.map((vereador) => (
            <Grid item xs={12} sm={6} md={4} lg={2.4} key={vereador.id}>
              <Tooltip title={vereador.descricao} arrow>
                <Card
                  sx={{
                    "&:hover": { boxShadow: 6 },
                    height: "100%",
                    borderRadius: "12px",
                    textDecoration: "none",
                  }}
                >
                  <CardActionArea href={`/vereadores/${vereador.id}`} sx={{ textDecoration: "none" }}>
                    <CardMedia
                      component="img"
                      height="240"
                      image={vereador.imagem}
                      alt={vereador.nome}
                      sx={{ objectFit: "cover", borderRadius: "12px 12px 0 0" }}
                    />
                    <CardContent sx={{ textAlign: "center", padding: 2 }}>
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1, textDecoration: "none", }}>
                        <Avatar sx={{ bgcolor: "primary.main", width: 28, height: 28 }}>
                          <Gavel sx={{ fontSize: 18 }} />
                        </Avatar>
                        <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "1.1rem", textDecoration: "none", }}>
                          {vereador.nome}
                        </Typography>
                      </Box>
                      <Divider sx={{ marginY: 1 }} />
                      <Typography variant="body2" sx={{ fontSize: "0.9rem", color: "text.secondary", textDecoration: "none", }}>
                        <AccountBalance sx={{ fontSize: 16, verticalAlign: "middle", marginRight: 0.5 }} /> {vereador.partido}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Vereadores;

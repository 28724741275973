import React, { useState, useRef } from 'react';
import {
  TextField, Button, MenuItem, Select, InputLabel, FormControl, Grid, Container, Box,
  Typography
} from '@mui/material';
import { getDatabase, ref, set, get, child } from 'firebase/database';
import firebaseApp, { KEY_REF } from '../adm/admin/firebaseConfig';
import TicketComponent from './Ticket';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const databaseInstance = getDatabase(firebaseApp);

const SaoJoao = () => {
  const [formValues, setFormValues] = useState({
    nome: '',
    tax: '',
    categoria: '',
    tamanho: '',
    opcaoPagamento: '',
    status: 'AGUARDANDO CONFIRMAÇÃO'
  });

  const [paymentMethod, setPaymentMethod] = useState('');
  const [showTicket, setShowTicket] = useState(false);
  const [ticketNumber, setTicketNumber] = useState('');
  const ticketRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handlePaymentMethodChange = (e) => {
    const value = e.target.value;
    setPaymentMethod(value);
    setFormValues({
      ...formValues, 
      opcaoPagamento: value,
    });
  };

  const handleTaxChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatTax(value);
    setFormValues({
      ...formValues,
      tax: formattedValue
    });
  };

  const formatTax = (value) => {
    value = value.replace(/\D/g, '');
    if (value.length <= 11) {
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else {
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const lastTicketNumber = await getLastTicketNumber();
    const newTicketNumber = (parseInt(lastTicketNumber, 10) + 1).toString().padStart(6, '0');
    const paymentData = {
      ...formValues,
      modalidade: 'bodenarua',
      ticket: newTicketNumber
    };
    const newPaymentRef = ref(databaseInstance, `pagamentos/${newTicketNumber}`);
    set(newPaymentRef, paymentData).then(() => {
      setTicketNumber(newTicketNumber);
      setShowTicket(true);
    });
  };

  const getLastTicketNumber = async () => {
    const dbRef = ref(databaseInstance);
    const snapshot = await get(child(dbRef, `pagamentos`));
    if (snapshot.exists()) {
      const tickets = snapshot.val();
      const ticketNumbers = Object.keys(tickets).map(ticket => parseInt(ticket, 10));
      return Math.max(...ticketNumbers).toString().padStart(6, '0');
    } else {
      return '000000';
    }
  };

  const handleDownload = () => {
    const input = ticketRef.current;
    html2canvas(input, { scale: 2, useCORS: true }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('ticket.pdf');
    });
  };

  const pixKey = "12345678900";
  const bankDetails = "Banco XYZ, Ag: 0000, Conta: 12345-6";

  return (
    <Container maxWidth="sm">
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="nome"
              fullWidth
              label="Nome"
              value={formValues.nome}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="tax"
              fullWidth
              label="CPF/CNPJ"
              value={formValues.tax}
              onChange={handleTaxChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Categoria</InputLabel>
              <Select
                name="categoria"
                value={formValues.categoria}
                onChange={handleInputChange}
                label="Categoria"
              >
                <MenuItem value="Artesanato">Artesanato</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Preferências</InputLabel>
              <Select
                name="tamanho"
                value={formValues.tamanho}
                onChange={handleInputChange}
                label="Tamanho"
              >
                <MenuItem value="Padrão">Padrão</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Opção de Pagamento</InputLabel>
              <Select
                name="opcaoPagamento"
                value={formValues.opcaoPagamento}
                onChange={handlePaymentMethodChange}
                label="Opção de Pagamento"
              >
                <MenuItem value="PIX">PAGAMENTO VIA PIX</MenuItem>
                <MenuItem value="Deposito">DEPOSITO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {paymentMethod === 'PIX' && (
            <Grid item xs={12}>
              <Typography>{pixKey}</Typography>
            </Grid>
          )}
          {paymentMethod === 'Deposito' && (
            <Grid item xs={12}>
              <Typography>{bankDetails}</Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" color="primary">
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Box>

      {showTicket && (
        <>
          <div ref={ticketRef} style={{ margin: '20px auto', padding: '20px', backgroundColor: '#fff', maxWidth: '100%', boxSizing: 'border-box' }}>
            <TicketComponent data={{ ...formValues, ticket: ticketNumber }} />
          </div>
          <Button onClick={handleDownload} variant="contained" color="secondary" sx={{ mt: 2 }}>
            Baixar Ticket
          </Button>
        </>
      )}
    </Container>
  );
};

export default SaoJoao;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './css/cabecalho.css';
import './css/slide.css';
import './css/Menu.css';
import './css-wesome/all.min.css';
import { getDatabase, ref, get, onValue } from 'firebase/database';
import firebaseApp, { camara, cidade, KEY_REF } from './adm/admin/firebaseConfig';
import { IconButton, useMediaQuery, Alert } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Typography, Box, Button } from '@mui/material';
import { Facebook, Instagram, YouTube, WhatsApp } from '@mui/icons-material';
import Header from './utils/Header';


export default function Cabecalho({ config }) {
  const isMobile = useMediaQuery('(max-width: 768px)');

  const [maintenanceActive, setMaintenanceActive] = useState(false);
  const [maintenanceMessage, setMaintenanceMessage] = useState('');
  const [maintenanceTime, setMaintenanceTime] = useState(0);
  const [maintenanceColor, setMaintenanceColor] = useState('');
  const [maintenanceTimeActive, setMaintenanceTimeActive] = useState(false);
  const isDesktop = window.innerWidth > 768;
  const currentDate = new Date().toLocaleDateString('pt-BR', {
    weekday: 'long',
    day: 'numeric',
    month: isDesktop ? 'long' : 'numeric',
    year: 'numeric',
  });

  const estiloIcones = {
    color: 'white',
    transition: 'color 0.3s ease',
    '&:hover': {
      color: '#1d1d1d',
    }
  };


  // useEffect(() => {
  //   const databaseInstance = getDatabase(firebaseApp);
  //   const maintenanceRef = ref(databaseInstance, ` manutencao`);

  //   const unsubscribe = onValue(maintenanceRef, (snapshot) => {
  //     const data = snapshot.val();

  //     if (data) {
  //       setMaintenanceActive(data.ativo);
  //       setMaintenanceMessage(data.msg);
  //       setMaintenanceTime(data.tempo);
  //       setMaintenanceColor(data.cor);
  //       setMaintenanceTimeActive(data.time_ativo);
  //     }
  //   });

  //   return () => unsubscribe();
  // }, []);

  useEffect(() => {
    const fetchMaintenanceData = async () => {
      try {
        const databaseInstance = getDatabase(firebaseApp);
        const maintenanceRef = ref(databaseInstance, `manutencao`);
        const snapshot = await get(maintenanceRef);

        if (snapshot.exists()) {
          const data = snapshot.val();
          setMaintenanceActive(data.ativo);
          setMaintenanceMessage(data.msg);
          setMaintenanceTime(data.tempo);
          setMaintenanceColor(data.cor);
          setMaintenanceTimeActive(data.time_ativo);
        }
      } catch (error) {
        console.error("Error fetching maintenance data:", error);
      }
    };

    fetchMaintenanceData();
  }, []);



  return (
    <header>
      <Box
        component="nav"
        sx={{
          background: config.background,
          padding: '8px 0',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          borderBottom: '0px solid white',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: isMobile ? 'center' : 'space-between',
            alignItems: 'center',
            padding: '0 24px',
            flexWrap: isMobile ? 'wrap' : 'nowrap',
            textAlign: isMobile ? 'center' : 'left',
          }}
        >
          {!isMobile && (
            <Typography
              variant="body1"
              sx={{
                fontWeight: 'bold',
                color: 'white',
                whiteSpace: 'nowrap',
              }}
            >
              <i className="fa-solid fa-calendar-days"></i> {cidade}, {currentDate}
            </Typography>
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: 'center',
              gap: isMobile ? 1 : 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: isMobile ? 'center' : 'flex-start',
                gap: 1,
                marginBottom: isMobile ? 1 : 0,
              }}
            >
              <Link to={"/transparencia"} style={{ textDecoration: 'none' }}>
                <Button
                  sx={{
                    fontWeight: 'bold',
                    color: '#000',
                    backgroundColor: 'white',
                    marginRight: isMobile ? 0 : 0,
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                    '&:hover': {
                      backgroundColor: '#1d1d1d',
                      color: 'white',
                    },
                  }}
                >
                  Transparência Fiscal
                </Button>
              </Link>
              {!camara && <Link to={"/app/notasfiscais"} style={{ textDecoration: 'none' }}>
                <Button
                  sx={{
                    fontWeight: 'bold',
                    color: '#000',
                    backgroundColor: 'white',
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                    '&:hover': {
                      backgroundColor: '#1d1d1d',
                      color: 'white',
                    },
                  }}
                >
                  NFS-E
                </Button>
              </Link>}

            </Box>
            {config?.design?.socialMedia && <>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: isMobile ? 'center' : 'flex-start'
                }}
              >
                <IconButton
                  component="a"
                  href={config.links.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={estiloIcones}
                >
                  <Facebook sx={{ fontSize: 28 }} />
                </IconButton>
                <IconButton
                  component="a"
                  href={config.links.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={estiloIcones}
                >
                  <Instagram sx={{ fontSize: 28 }} />
                </IconButton>
                <IconButton
                  component="a"
                  href={config.links.youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={estiloIcones}
                >
                  <YouTube sx={{ fontSize: 28 }} />
                </IconButton>
                <IconButton
                  component="a"
                  href={config.links.whatsapp}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={estiloIcones}
                >
                  <WhatsApp sx={{ fontSize: 28 }} />
                </IconButton>
              </Box>
            </>}

          </Box>
        </Box>
      </Box>

      <Box className="slide">
        <Link href={config.bannerLink} underline="none">
          <Box
            component="img"
            src={config?.banner || "https://publish.nstu.ru/bitrix/templates/aspro-scorp/images/background.png"}
            alt="Banner"
            className="imagem"

          />
        </Link>
      </Box>

      <Header config={config} />

      {maintenanceActive && maintenanceTime > 0 && (
        <Box sx={{ padding: 1 }}>


          <Alert
            icon={<WarningAmberIcon fontSize="inherit" sx={{ color: 'white' }} />}
            severity="warning"
            sx={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              bgcolor: maintenanceColor,
              color: 'white',
              padding: 2,
              mb: 2,
              boxShadow: 3,
            }}
          >
            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
              {maintenanceMessage}
            </Typography>
            {maintenanceTimeActive && (
              <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                <AccessTimeIcon fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="body2" fontWeight="bold" s >
                  Tempo restante estimado: {Math.floor(maintenanceTime / 3600)}h {Math.floor((maintenanceTime % 3600) / 60)}m {maintenanceTime % 60}s
                </Typography>
              </Box>
            )}
          </Alert>


        </Box>
      )}
    </header>
  );
}

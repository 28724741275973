import React, { useEffect, useState } from "react";
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
    Grid,
    Container,
    Avatar,
    Divider,
    Tooltip,
} from "@mui/material";
import { Gavel, AccountBalance } from "@mui/icons-material";
import { database, KEY_REF } from "./adm/admin/firebaseConfig";
import { ref, query, orderByChild, limitToFirst, get } from "firebase/database";
import TitleSection from "./utils/TittleTheme";

const CACHE_EXPIRATION = 5 * 60 * 1000; // 5 minutos

const fetchVereadores = async (setVereadores) => {
    try {
        if (!KEY_REF) {
            console.error("KEY_REF não definido.");
            return;
        }

        const CACHE_KEY = `vereadores_cache_${KEY_REF}`;

        const cachedData = localStorage.getItem(CACHE_KEY);
        if (cachedData) {
            const { data, timestamp } = JSON.parse(cachedData);
            if (Date.now() - timestamp < CACHE_EXPIRATION) {
                setVereadores(data);
                return;
            }
        }

        const vereadoresRef = ref(database, `secretarios`);
        const vereadoresQuery = query(vereadoresRef, orderByChild("nome"), limitToFirst(50));

        const snapshot = await get(vereadoresQuery);
        const data = snapshot.val();

        if (data) {
            const vereadoresArray = Object.keys(data).map((key) => ({
                id: key,
                nome: data[key].nome || "",
                cargo: data[key].cargo || "",
                imagem: data[key].imagem || ""
            }));

            setVereadores(vereadoresArray);

            localStorage.setItem(CACHE_KEY, JSON.stringify({ data: vereadoresArray, timestamp: Date.now() }));
        } else {
            console.warn(`Nenhum dado encontrado para vereadores (${KEY_REF})`);
            setVereadores([]);
        }
    } catch (error) {
        console.error(`Erro ao buscar os vereadores (${KEY_REF}):`, error);
    }
};

const Secretarios = () => {
    const [vereadores, setVereadores] = useState([]);

    useEffect(() => {
        fetchVereadores(setVereadores);
    }, [KEY_REF]);

    return (
        <Box sx={{ marginTop: 3 }}>
            <TitleSection
                title="SECRETÁRIOS MUNICIPAIS"
                subtitle="Conheça os líderes que atuam na gestão pública municipal, trabalhando para melhorar os serviços e atender às necessidades da comunidade."
            />

            <Container maxWidth="lg" sx={{ paddingX: { xs: 2, md: 2 }, marginY: 3 }}>
                <Grid container spacing={3}>
                    {vereadores.map((vereador) => (
                        <Grid item xs={12} sm={6} md={4} lg={2.4} key={vereador.id}>
                            <Tooltip title={vereador.descricao} arrow>
                                <Card
                                    sx={{
                                        "&:hover": { boxShadow: 6 },
                                        height: "100%",
                                        borderRadius: "12px",
                                        textDecoration: "none",
                                    }}
                                >
                                    <CardActionArea
                                        href={`/secretarios/${vereador.id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{ textDecoration: "none" }}
                                    >    <CardMedia
                                            component="img"
                                            height="240"
                                            image={vereador.imagem}
                                            alt={vereador.nome}
                                            sx={{ objectFit: "cover", borderRadius: "12px 12px 0 0" }}
                                        />
                                        <CardContent sx={{ textAlign: "center", padding: 2,textDecoration: "none" }}>
                                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1, textDecoration: "none", }}>

                                                <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "1.1rem", textDecoration: "none", }}>
                                                    {vereador.nome}
                                                </Typography>
                                            </Box>
                                            <Divider sx={{ marginY: 1 }} />
                                            <Typography variant="body2" sx={{ fontSize: "0.9rem", color: "text.secondary", textDecoration: "none", }}>
                                                <AccountBalance sx={{ fontSize: 16, verticalAlign: "middle", marginRight: 0.5 }} /> {vereador.cargo}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Tooltip>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default Secretarios;

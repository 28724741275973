import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography, List, ListItem, ListItemText, Box, Snackbar, IconButton, Divider, Container, Grid, Button } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SecurityIcon from '@mui/icons-material/Security';
import GppGoodIcon from '@mui/icons-material/GppGood';
import LinkIcon from '@mui/icons-material/Link';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ExploreIcon from '@mui/icons-material/Explore';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LogoDevIcon from '@mui/icons-material/LogoDev';
import GoTopo from './GoTopo';
import { camara, cidade, gurjao } from './adm/admin/firebaseConfig';

function Footer({ config }) {
  const [open, setOpen] = useState(false);
  const [copiedText, setCopiedText] = useState('');

  const handleCopy = (text) => {
    setCopiedText(text);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };


  return (
    <>
      <GoTopo config={config} />
      <Box component="footer" sx={{ backgroundColor: config.color, padding: '24px 0', color: '#FFFFFF' }}>

        <Container maxWidth="lg">

          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" gutterBottom>
                Sobre <InfoIcon />
              </Typography>
              <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                {config.resumoHistorico}
              </Typography>
              <Box display="flex" justifyContent="center" mt={2}>
                <Button
                  variant="contained"

                  startIcon={<ExploreIcon />}
                  component={Link}
                  to="/historia"
                  sx={{
                    color: config.color, backgroundColor: 'white', fontWeight: 'bold', '&:hover': {
                      color: config.color, backgroundColor: config.colorSecondary
                    }
                  }}
                >
                  Sobre
                </Button>

              </Box>
            </Grid>


            {!camara &&


              <Grid item xs={6} md={3}>
                <Typography variant="h6" gutterBottom>
                  Documentos
                </Typography>
                <List>
                  {[
                    { path: "/arquivos/contratos", title: "Contratos" },
                    { path: "/legislacao/decretos", title: "Decretos" },
                    { path: "/arquivos/editais", title: "Editais" },
                    { path: "/arquivos/farmaciabasica", title: "Estoque Farmácia Básica" },
                    { path: "/arquivos/frota", title: "Frota Municipal" },
                    { path: "/legislacao/portarias", title: "Portarias" },
                    { path: "/arquivos/quadro", title: "Quadro Funcional" },
                    { path: "/processo_seletivo", title: "Processo Seletivo" }
                  ].map((item) => (
                    <ListItem key={item.title} disablePadding>
                      <ListItemText primary={<Link to={item.path} style={{ textDecoration: 'none', color: '#FFFFFF' }}>{item.title}</Link>} />
                    </ListItem>
                  ))}
                </List>

              </Grid>
            }



            <Grid item xs={6} md={3}>
              {camara ? (<>
                <Typography variant="h6" gutterBottom>
                  Links úteis <LinkIcon />
                </Typography>
                <List>
                  {[
                    { path: "/legislacao/requerimentos", title: "Requerimentos" },
                    { path: "/legislacao/leismunicipais", title: "Leis Municipais" },
                    { path: "/transparencia", title: "Transparência Fiscal" },
                    { path: "/legislacao/projetodelei", title: "Projetos de Lei" }
                  ].map((item) => (
                    <ListItem key={item.title} disablePadding>
                      <ListItemText primary={<Link to={item.path} style={{ textDecoration: 'none', color: '#FFFFFF' }}>{item.title}</Link>} />
                    </ListItem>
                  ))}
                </List>

              </>) : (<>

                <Typography variant="h6" gutterBottom>
                  Links úteis <LinkIcon />
                </Typography>
                <List>
                  {[
                    { path: "/arquivos/contratos", title: "Contratos" },
                    { path: "/arquivos/frota", title: "Frota Municipal" },
                    { path: "/rh/cc", title: "Contra-cheque" },
                    { path: "/transparencia", title: "Transparência Fiscal" },
                    { path: "/legislacao/codigotributario", title: "Código Tributário" }
                  ].map((item) => (
                    <ListItem key={item.title} disablePadding>
                      <ListItemText primary={<Link to={item.path} style={{ textDecoration: 'none', color: '#FFFFFF' }}>{item.title}</Link>} />
                    </ListItem>
                  ))}
                </List>

              </>)}


              {gurjao && (<>
                <Typography variant="h6" gutterBottom sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                  Transparência <AssessmentIcon sx={{ ml: 1 }} />
                </Typography>
                <List>
                  {[
                    { path: "/transparencia", title: "Nota máxima no TRE-PB - Turmalina" },

                  ].map((item) => (
                    <ListItem key={item.title} disablePadding>
                      <ListItemText primary={<Link to={item.path} style={{ textDecoration: 'none', color: '#FFFFFF' }}>{item.title}</Link>} />
                    </ListItem>
                  ))}
                </List>

              </>)}


            </Grid>

            <Grid item xs={12} md={3}>

              {config?.design?.contatos && <>


                <Typography variant="h6" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                  Contatos
                </Typography>
                <Typography variant="body2" display="flex" alignItems="center" sx={{ cursor: 'pointer', color: '#FFFFFF', marginBottom: '4px' }}>
                  <CopyToClipboard text={config.email} onCopy={() => handleCopy('E-mail')}>
                    <span><EmailIcon sx={{ marginRight: '8px' }} /> {config.email}</span>
                  </CopyToClipboard>
                </Typography>
                <Typography variant="body2" display="flex" alignItems="center" sx={{ cursor: 'pointer', color: '#FFFFFF' }}>
                  <CopyToClipboard text={config.telefone} onCopy={() => handleCopy('Telefone')}>
                    <span><PhoneIcon sx={{ marginRight: '8px' }} /> {config.telefone}</span>
                  </CopyToClipboard>
                </Typography>

              </>
              }


              {!camara && (<>
                <Divider sx={{ marginY: '15px', backgroundColor: '#FFFFFF' }} />
                <Typography variant="body2" display="flex" alignItems="center" sx={{ color: '#FFFFFF' }}>
                  <SecurityIcon sx={{ marginRight: '8px' }} /> Proteção e segurança
                </Typography>
                <List>
                  {[
                    { path: "/prefeitura/lgpd", title: "Este site é protegido e cumpre as diretrizes da LGPD." },

                  ].map((item) => (
                    <ListItem key={item.title} disablePadding>
                      <ListItemText primary={<Link to={item.path} style={{ textDecoration: 'none', color: '#FFFFFF' }}>{item.title}</Link>} />
                    </ListItem>
                  ))}
                </List>
              </>)}



              {gurjao && (<>
                <Divider sx={{ marginY: '15px', backgroundColor: '#FFFFFF' }} />
                <a style={{ textDecoration: 'none' }} href={'/certificado_ssl/gurjao.pb.gov.br.crt'} download>
                  <Typography variant="body2" display="flex" alignItems="center" sx={{ color: '#FFFFFF', marginTop: '4px' }}>
                    <GppGoodIcon sx={{ marginRight: '8px' }} /> Certificado Digital de Segurança
                  </Typography>
                </a>
              </>)}


            </Grid>
          </Grid>

          <Divider sx={{ marginY: '16px', backgroundColor: '#FFFFFF' }} />

          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="body2">
                &copy; 2025 - {config.orgaoNome} | CNPJ: {config.cnpj} | <LogoDevIcon /> {config.dev}
              </Typography>
            </Grid>

            {config?.design?.socialMedia && <>
              <Grid item>
                <Box display="flex" gap={1}>
                  {[
                    { icon: <FacebookIcon />, link: config.links.facebook },
                    { icon: <InstagramIcon />, link: config.links.instagram },
                    { icon: <YouTubeIcon />, link: config.links.youtube },
                    { icon: <WhatsAppIcon />, link: config.links.whatsapp }
                  ].map((social, index) => (
                    <IconButton key={index} color="inherit" component="a" href={social.link} target="_blank" rel="noopener noreferrer">
                      {social.icon}
                    </IconButton>
                  ))}
                </Box>
              </Grid>

            </>}

          </Grid>
        </Container>
      </Box>
    </>

  );
}

export default Footer;

import React, { useState, useEffect } from 'react';
import './pages_documentos.css';
import { getDatabase, ref, get } from 'firebase/database';
import firebaseApp, { KEY_LINK, KEY_REF } from '../adm/admin/firebaseConfig';
import { TextField, Select, MenuItem, FormControl, InputLabel, Grid, Box } from '@mui/material';
import TitleSection from '../utils/TittleTheme';
import DataTable from '../utils/TabelaLicitacoes';



export default function ArqLicitacoes() {
    const [newsData, setNewsData] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const databaseInstance = getDatabase(firebaseApp);
                const noticiasRef = ref(databaseInstance, `licitacoesDocumentos`);

                const snapshot = await get(noticiasRef);
                const data = snapshot.val();

                if (data) {
                    const newsArray = Object.keys(data).map((key) => {
                        const item = data[key];
                        return {
                            id: key,
                            title: item.titulo,
                            numero: item.numero || null,
                            data: item.data,
                            descricao: item.descricao,
                            modalidade: item.modalidade,
                            hrefs: {
                                contrato: item.contratoDoc || null,
                                outros: item.outrosDoc || null,
                                edital: item.editalDoc || null,
                                publicidade: item.publicidadeDoc || null
                            }
                        };
                    });
                    setNewsData(newsArray);
                }
            } catch (error) {
                console.error('Erro ao carregar documentos:', error);
            }
        };

        fetchData();
    }, []);




    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <Box sx={{ padding: 3, maxWidth: '1200px', mx: 'auto' }}>
            <TitleSection title={'LICITAÇÕES'} subtitle={'Documentos e informações sobre processos licitatórios'} share={true} />

            <DataTable data={newsData} />
        </Box>
    );
}

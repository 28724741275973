import React, { useEffect, useState } from 'react';
import {
    Container,
    Grid,
    Box,
    Button,
    Typography,
    Modal,
    Fade,
    Backdrop,
    IconButton,
    Paper
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import ImageIcon from '@mui/icons-material/Image';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getDatabase, ref, get } from 'firebase/database';
import firebaseApp, { KEY_REF } from '../adm/admin/firebaseConfig';

const GaleriaMidiaPage = () => {
    const [view, setView] = useState(null);
    const [noticias, setNoticias] = useState([]);
    const [galerias, setGalerias] = useState([]);
    const [selectedGallery, setSelectedGallery] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const database = getDatabase(firebaseApp);
            const noticiasRef = ref(database,  `noticias`);
            const galeriaRef = ref(database,  `galeria`);

            const [noticiasSnap, galeriaSnap] = await Promise.all([get(noticiasRef), get(galeriaRef)]);

            if (noticiasSnap.exists()) {
                const noticiasData = noticiasSnap.val();
                const noticiasList = Object.values(noticiasData).map(noticia => ({
                    titulo: noticia.titulo,
                    data: noticia.data,  // Adicionando a data da notícia
                    imagens: [noticia.imagem, noticia.imagem1, noticia.imagem2, noticia.imagem3, noticia.imagem4].filter(img => typeof img === 'string' && img.startsWith('http'))
                }));
                setNoticias(noticiasList);
            }

            if (galeriaSnap.exists()) {
                const galeriaData = galeriaSnap.val();
                const galeriasList = Object.keys(galeriaData).map(key => {
                    const imagens = Object.values(galeriaData[key]).filter(img => typeof img === 'string' && img.startsWith('http'));
                    return {
                        titulo: galeriaData[key].titulo || key,
                        imagens: imagens
                    };
                }).filter(galeria => galeria.imagens.length > 0);
                setGalerias(galeriasList);
            }
        };

        fetchData();
    }, []);

    const handleOpenImage = (image) => {
        setSelectedImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleBackToHome = () => {
        setView(null);
        setSelectedGallery(null); // Limpa a galeria selecionada
    };

    const handleSelectGallery = (galeria) => {
        setSelectedGallery(galeria);
    };

    const handleSelectNoticias = () => {
        setView('noticias');
        setSelectedGallery(null); // Limpa a galeria selecionada
    };

    const handleSelectGaleria = () => {
        setView('galeria');
        setSelectedGallery(null); // Limpa a galeria selecionada
    };

    return (
        <Container maxWidth="lg" sx={{ py: 5 }}>
            {!view ? (
                <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleSelectNoticias}
                            sx={{ py: 3, fontSize: '1.2rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <ImageIcon sx={{ mr: 1 }} /> Galeria de Notícias
                        </Button>
                        <Typography variant="body2" sx={{ textAlign: 'center', mt: 1 }}>
                            Explore as últimas notícias e imagens.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={handleSelectGaleria}
                            sx={{ py: 3, fontSize: '1.2rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <FolderIcon sx={{ mr: 1 }} /> Galeria de Mídia
                        </Button>
                        <Typography variant="body2" sx={{ textAlign: 'center', mt: 1 }}>
                            Visualize nossa coleção de mídias e imagens.
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <>
                    <IconButton
                        variant="outlined"
                        onClick={handleBackToHome}
                        sx={{ mb: 2, color: 'primary.main' }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    {view === 'noticias' && (
                        <Grid container spacing={2} sx={{ mt: 3 }}>
                            {noticias.map((noticia, index) => (
                                noticia.imagens.map((img, i) => (
                                    <Grid item xs={12} sm={6} md={3} key={`${index}-${i}`}>
                                        <Box
                                            component="img"
                                            src={img}
                                            alt={noticia.titulo}
                                            onClick={() => handleOpenImage(img)}
                                            sx={{
                                                width: '100%',
                                                borderRadius: 2,
                                                cursor: 'pointer',
                                                boxShadow: 2,
                                                transition: 'all 0.3s ease',
                                                '&:hover': { transform: 'scale(1.05)', boxShadow: 5 }
                                            }}
                                        />
                                        {/* Exibindo título e data na galeria de notícias em desktop */}
                                        <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
                                            {noticia.titulo} - {noticia.data}
                                        </Typography>
                                    </Grid>
                                ))
                            ))}
                        </Grid>
                    )}

                    {view === 'galeria' && !selectedGallery && (
                        <Grid container spacing={2} sx={{ mt: 3 }}>
                            {galerias.map((galeria, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        onClick={() => handleSelectGallery(galeria)}
                                        sx={{
                                            py: 3,
                                            fontSize: '1.1rem',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            boxShadow: 2,
                                            transition: 'all 0.3s ease',
                                            '&:hover': { transform: 'scale(1.05)', boxShadow: 5 }
                                        }}
                                    >
                                        <FolderIcon sx={{ mr: 1 }} /> {galeria.titulo}
                                    </Button>
                                    <Typography variant="body2" sx={{ textAlign: 'center', mt: 1 }}>
                                        Explore as imagens desta galeria.
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {selectedGallery && (
                        <>
                            <Button variant="outlined" onClick={() => setSelectedGallery(null)} sx={{ mb: 2 }}>
                                Voltar
                            </Button>
                            <Grid container spacing={2} sx={{ mt: 3 }}>
                                {selectedGallery.imagens.map((img, i) => (
                                    <Grid item xs={12} sm={6} md={3} key={i}>
                                        <Box
                                            component="img"
                                            src={img}
                                            alt={selectedGallery.titulo}
                                            onClick={() => handleOpenImage(img)}
                                            sx={{
                                                width: '100%',
                                                borderRadius: 2,
                                                cursor: 'pointer',
                                                boxShadow: 2,
                                                transition: 'all 0.3s ease',
                                                '&:hover': { transform: 'scale(1.05)', boxShadow: 5 }
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                </>
            )}
            <Modal open={open} onClose={handleClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
                <Fade in={open}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 2,
                            borderRadius: 2,
                            maxWidth: '90%',
                            maxHeight: '90%',
                            cursor: 'pointer'
                        }}
                        onClick={handleClose}
                    >
                        <Box component="img" src={selectedImage} alt="Imagem Ampliada" sx={{ width: '100%', maxHeight: '80vh', objectFit: 'contain' }} />
                    </Box>
                </Fade>
            </Modal>
        </Container>
    );
};

export default GaleriaMidiaPage;

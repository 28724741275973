import React, { useState, useEffect } from 'react';
import {
  Box
} from '@mui/material';
import { getDatabase, ref, get, query, orderByChild, limitToLast } from 'firebase/database';
import firebaseApp, { KEY_LINK, KEY_REF } from '../adm/admin/firebaseConfig';
import TitleSection from '../utils/TittleTheme';
import DataTable from '../utils/Tabela';


export default function LeisMunicipais() {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const fetchData = async () => {


      if (!KEY_REF) {
        console.error("KEY_REF não definido.");
        
        return;
      }

      try {
        const databaseInstance = getDatabase(firebaseApp);
        const leisRef = query(ref(databaseInstance, `leismunicipais`));

    
        const snapshot = await get(leisRef);
        const data = snapshot.val();

        if (data) {
          const newsArray = Object.keys(data).map((key) => ({
            id: key,
            title: data[key].nome,
            numero: data[key].numero || null,
            mes: data[key].mes,
            dia: data[key].dia || null,
            ano: data[key].ano,
            href: data[key].href
          }));

          setNewsData(newsArray);

          const dataSizeMB = new TextEncoder().encode(JSON.stringify(data)).length / (1024 * 1024);
       } else {
          setNewsData([]);
       }
      } catch (error) {
        console.error("Erro ao carregar leis municipais:", error);
      } 
    };

    fetchData();
  }, [KEY_REF]); 






  return (
    <Box sx={{ padding: 3, maxWidth: '1200px', mx: 'auto' }}>


      <TitleSection title={'LEIS MUNICIPAIS'} subtitle={'Normas legais aprovadas pela Câmara Municipal que regulam direitos, deveres e serviços no âmbito do município.'} />

      <DataTable data={newsData} />
    </Box>
  );
}

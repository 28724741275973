import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  Box,
  Button,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Collapse, TextField, CircularProgress, InputAdornment,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent
} from '@mui/material';
import * as MuiIcons from '@mui/icons-material';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Menu as MenuIcon,
  Home as HomeIcon,
  Search as SearchIcon,
  AccountBalance as AccountBalanceIcon,
  Description as DescriptionIcon,
  Group as GroupIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link, useNavigate } from 'react-router-dom';
import { get, ref } from 'firebase/database';
import firebaseApp, { camara, cidade, database, KEY_REF, removeAccents } from '../adm/admin/firebaseConfig';
import GavelIcon from '@mui/icons-material/Gavel';
import ArticleIcon from '@mui/icons-material/Article';
import BalanceIcon from '@mui/icons-material/Balance';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PeopleIcon from '@mui/icons-material/People';

const Logo = styled('img')(({ theme }) => ({
  width: '200px',
  [theme.breakpoints.down('sm')]: {
    width: '200px',
  },
}));


export default function Header({ config }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isVerySmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const [secretarios, setSecretarios] = useState([]);
  const [vereadores, setVereadores] = useState([]);
  const iconSize = isVerySmallScreen ? 'small' : isSmallScreen ? 'medium' : 'default';
  const fontSize = isVerySmallScreen ? '0.7rem' : isSmallScreen ? '0.85rem' : '0.95rem';
  const [importantLinksAnchorEl, setImportantLinksAnchorEl] = useState(null); // Novo estado
  const closeImportantLinksMenu = () => setImportantLinksAnchorEl(null);


  const [showDialog, setShowDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [subMenuOpen, setSubMenuOpen] = useState({
    secretarias: false,
    prefeitura: false,
    documentos: false,
    recursosHumanos: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };
  // 
  if (camara) {
    const vereadoresRef = ref(database, `vereadores`);

    const fetchVereadores = async () => {
      try {
        const snapshot = await get(vereadoresRef);
        const data = snapshot.val();

        if (data) {
          const vereadoresArray = Object.keys(data).map((key) => ({
            id: key,
            nome: data[key].nome,
          }));

          vereadoresArray.sort((a, b) => a.nome.localeCompare(b.nome));

          setVereadores(vereadoresArray);
        } else {
          setVereadores([]);
        }
      } catch (error) {
        console.error("Erro ao buscar vereadores:", error);
      }
    };

    fetchVereadores();
  }

  if (!camara) {
    const secretariosRef = ref(database, `secretarios`);

    const fetchSecretarios = async () => {
      try {
        const snapshot = await get(secretariosRef);
        const data = snapshot.val();

        if (data) {
          const secretariosArray = Object.keys(data).map((key) => ({
            id: key,
            cargo: data[key].cargo,
            nome: data[key].nome,
          }));
          setSecretarios(secretariosArray);
        } else {
          setSecretarios([]);
        }
      } catch (error) {
        console.error("Erro ao buscar secretários:", error);
      }
    };

    fetchSecretarios();
  }


  const toggleSubMenu = (event, menu) => {
    if (isMobile) {
      setSubMenuOpen((prevState) => ({
        ...prevState,
        [menu]: !prevState[menu],
      }));
    } else {
      setAnchorEl(event.currentTarget);
      setSubMenuOpen({
        [menu]: true,
      });
    }
  };

  const closeSubMenu = () => {
    setAnchorEl(null);
    setSubMenuOpen({
      secretarias: false,
      prefeitura: false,
      documentos: false,
      recursosHumanos: false,
    });
  };


  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(async () => {
      const headerLinks = [
        // Prefeitura
        { path: "/", title: "Início", keywords: ["home", "pagina inicial", "pagina inicial", "inicio", "inicial", "começo", "comeco", "comeco"] },
        { path: "/prefeitura/prefeito", title: "Prefeito", keywords: ["gestor", "governante", "administrador", "líder", "lider", "prefeito"] },
        { path: "/prefeitura/viceprefeito", title: "Vice-Prefeito", keywords: ["vice", "sub-prefeito", "subprefeito", "assistente", "co-gestor", "cogestor", "viceprefeito", "vice prefeito"] },
        { path: "/transparencia", title: "Transparência Fiscal", keywords: ["impostos", "contas", "financeiro", "tributos", "prestação de contas", "prestacao de contas", "prestacao contas", "transparencia", "receita", "despesa", "covid", "convenio", "estagiario", "renuncia", "informacao", "informacoes"] },
        { path: "/app/notasfiscais", title: "NFS-E", keywords: ["nota fiscal", "notas fiscais", "notafiscal", "nfse", "emissão", "emissao", "emissao nf", "recibo fiscal", "recibofiscal"] },
        { path: "/app/perguntas", title: "Perguntas", keywords: ["perguntas", "pergunta"] },

        { path: "/ouvidoria", title: "Ouvidoria", keywords: ["ouvidoria", "ouvidoria publica", "reclamações", "reclamacoes", "reclamacao", "sugestões", "sugestoes", "sugestao", "solicitações", "solicitacoes", "solicitacao", "denúncia", "denuncia", "contato ouvidoria", "contatoouvidoria"] },
        { path: "/e-sic", title: "e-SIC", keywords: ["e-sic", "esic", "acesso à informação", "acesso a informacao", "acesso informacao", "transparência", "transparencia", "consulta pública", "consulta publica", "solicitação de dados", "solicitacao de dados", "dados públicos", "dados publicos"] },
        { path: "/prefeitura/carta-de-servico", title: "Carta de Serviço", keywords: ["carta de serviço", "cartadeservico", "carta", "carta de atendimento", "informações de serviço", "informacoes de servico", "guias de serviço", "guias de servico", "carta municipal de serviços", "serviços municipais", "carta de servicos", "carta de servico"] },

        // Secretarias
        { path: "/secretarias/administracao", title: "Administração", keywords: ["admin", "gestão", "gestao", "administração", "administracao", "direção", "direcao"] },
        { path: "/secretarias/assistenciasocial", title: "Assistência Social", keywords: ["social", "assistente", "apoio social", "ajuda social", "bem-estar", "bem estar"] },
        { path: "/secretarias/cultura", title: "Cultura e Turismo", keywords: ["cultura", "turismo", "eventos", "patrimônio", "patrimonio", "turista"] },
        { path: "/secretarias/desenvolvimentorural", title: "Desenvolvimento Rural", keywords: ["rural", "agricultura", "campo", "desenvolvimento agrícola", "desenvolvimento agricola", "agronegócio", "agronegocio"] },
        { path: "/secretarias/educacao", title: "Educação", keywords: ["escolas", "ensino", "educacao", "aprendizado", "instituições", "instituicoes"] },
        { path: "/secretarias/financas", title: "Finanças", keywords: ["financeiro", "dinheiro", "contabilidade", "tesouraria", "financas", "finanças"] },
        { path: "/secretarias/meioambiente", title: "Meio Ambiente", keywords: ["ambiente", "ecologia", "natureza", "sustentabilidade", "meio ambiente", "meioambiente"] },
        { path: "/secretarias/saude", title: "Saúde", keywords: ["medico", "hospital", "saude", "saúde", "enfermagem", "clínica", "clinica"] },
        { path: "/setor/recursoshumanos", title: "Responsável pelo Setor", keywords: ["rh", "humanos", "gestão de pessoas", "gestao de pessoas", "recrutamento"] },
        { path: "/historia", title: "Historia", keywords: ["historia", "history"] },

        // Legislação
        { path: "/legislacao/leismunicipais", title: "Leis Municipais", keywords: ["leis", "legislação", "legislacao", "municipal", "regras", "regulamento"] },
        { path: "/legislacao/aldir_blanc", title: "Lei Aldir Blanc Nº 14.017", keywords: ["aldir blanc", "lei cultura", "subvenção cultural", "subvencao cultural", "apoio cultural", "financiamento cultural"] },
        { path: "/leipaulogustavo", title: "Lei Paulo Gustavo Nº 195/2022", keywords: ["paulo gustavo", "lei cultura", "cultura", "fomento cultural", "apoio financeiro"] },
        { path: "/legislacao/leiorganica", title: "Lei Orgânica", keywords: ["orgânica", "organica", "constituição", "constituicao", "fundamental", "regulamentação", "regulamentacao", "base legal"] },
        { path: "/legislacao/diariosoficial", title: "Diários Oficial", keywords: ["diário", "diario", "publicação", "publicacao", "informativo", "notícias oficiais", "noticias oficiais", "documento público", "documento publico"] },
        { path: "/legislacao/codigotributario", title: "Código Tributário", keywords: ["tributário", "tributario", "impostos", "taxas", "fiscal", "arrecadação", "arrecadacao"] },
        { path: "/legislacao/controleinterno", title: "Controle Interno Municipal", keywords: ["controle", "auditoria", "monitoramento", "fiscalização", "fiscalizacao", "verificação", "verificacao"] },

        // Arquivos e Documentação
        { path: "/app/iptu", title: "IPTU", keywords: ["imposto", "propriedade", "imovel", "iptu", "taxa de propriedade"] },
        { path: "/saladoempreendedor", title: "Sala do Empreendedor", keywords: ["empreendedorismo", "negócios", "empresa", "empreendedor", "microempresa"] },
        { path: "/licitacoes", title: "Licitações", keywords: ["licitacoes", "concorrência", "pregão", "processo licitatório"] },
        { path: "/avisos", title: "Avisos Sociais", keywords: ["avisos", "notificações", "comunicado", "informação pública", "noticias sociais"] },
        { path: "/noticias/todas", title: "Todas as Notícias", keywords: ["noticias", "noticia", "informações", "artigos", "reportagens", "novidades"] },
        { path: "/arquivos/loa", title: "LOA", keywords: ["loa", "orçamento", "orcamento", "orçamento anual", "orcamento anual", "lei orçamentária", "lei orcamentaria", "planejamento financeiro"] },
        { path: "/arquivos/ldo", title: "LDO", keywords: ["ldo", "diretrizes", "lei de diretrizes", "planejamento", "finanças públicas", "financas publicas"] },
        { path: "/arquivos/pca", title: "PCA", keywords: ["pca", "Plano de Contratação Anual"] },

        { path: "/arquivos/rreo", title: "RREO", keywords: ["rreo", "relatório", "relatorio", "execução orçamentária", "execucao orcamentaria", "balanço financeiro", "balanco financeiro", "prestação de contas", "prestacao de contas"] },
        { path: "/arquivos/rgf", title: "RGF", keywords: ["rgf", "gestão fiscal", "gestao fiscal", "responsabilidade fiscal", "balanço", "balanco", "demonstrativo fiscal"] },
        { path: "/arquivos/quadro", title: "Quadro Funcional", keywords: ["funcionários", "funcionarios", "funcional", "quadro", "folha de pagamento", "folha", "servidores", "empregados", "pessoal"] },
        { path: "/arquivos/contratos", title: "Contratos", keywords: ["contratos", "acordos", "convênios", "convenios", "convenio", "parcerias", "documentos legais"] },
        { path: "/legislacao/decretos", title: "Decretos", keywords: ["decreto", "normas", "regulamentação", "regulamentacao", "ordem executiva", "diretrizes"] },
        { path: "/arquivos/editais", title: "Editais", keywords: ["editais", "avisos", "concursos", "publicações", "publicacoes", "anúncios oficiais", "anuncios oficiais"] },
        { path: "/arquivos/farmaciabasica", title: "Estoque Fármacia Básica", keywords: ["farmácia", "farmacia", "remedio", "remédio", "estoque", "remédios", "remedios", "medicamentos", "saúde pública", "saude publica"] },
        { path: "/arquivos/frota", title: "Frota Municipal", keywords: ["veículos", "veiculos", "frota", "transporte", "carros oficiais", "automóveis", "automoveis"] },
        { path: "/arquivos/tabela-diarias", title: "Diarias", keywords: ["diarias", "tabela diaria", "tabela diarias", "diária"] },

        { path: "/legislacao/portarias", title: "Portarias", keywords: ["portaria", "regulamento", "diretiva", "instrução", "instrucao", "normativa"] },
        { path: "/processo_seletivo", title: "Processo Seletivo", keywords: ["seleção", "selecao", "vagas", "concurso", "recrutamento", "oportunidades"] },

        // Recursos Humanos 
        { path: "/rh/cc", title: "Contra-cheque", keywords: ["contra cheque", "contra-cheque", "holerite", "salário", "salario"] },
        { path: "/rh/documentos", title: "Requerimentos", keywords: ["pedido", "solicitações", "solicitacoes", "requisição", "requisicao", "solicitar", "ordem", "requerer", "formulários", "formularios", "solicitar", "pedido", "requisição", "requisicao"] },
        { path: "/rh/documentos", title: "Documentos Baixáveis", keywords: ["baixar", "documentos", "downloads", "arquivos", "impressões", "impressoes"] },
        { path: "/rh/fichafuncional", title: "Ficha Funcional", keywords: ["funcionario", "funcionário", "ficha", "perfil", "registro", "histórico", "historico"] },

        // Destaques dinâmicos
        ...(config.destaque ? [{ path: config.destaque.link, title: config.destaque.name, keywords: [] }] : []),
        ...(config.destaque2 ? [{ path: config.destaque2.link, title: config.destaque2.name, keywords: [] }] : []),
        ...(config.destaque3 ? [{ path: config.destaque3.link, title: config.destaque3.name, keywords: [] }] : []),
      ];

      const searchKeywords = searchTerm.toLowerCase();

      const contactKeywords = ["contato", "telefone", "email"];
      const isContactSearch = contactKeywords.some((keyword) => searchKeywords.includes(keyword));
      if (isContactSearch) {
        setLoading(false);
        // setSearchOpen(false);
        setSearchTerm('');
        return;
      }


      const socialMediaKeywords = {
        facebook: ["facebook", "gestão no facebook", "gestao no facebook"],
        instagram: ["instagram", "gestão no instagram", "gestao no instagram"],
        youtube: ["youtube", "gestão no youtube", "gestao no youtube", "canal no youtube"],
        whatsapp: ["whatsapp", "gestão no whatsapp", "gestao no whatsapp", "contato whatsapp"],
        imagens: ["imagens aereas", "drone", "imagem aerea", "aerea"]
      };


      if (socialMediaKeywords.facebook.some(keyword => searchKeywords.includes(keyword))) {
        window.open(`${config.links.facebook}`, "_blank");
        setLoading(false);
        setSearchTerm('');
        return;
      }


      if (socialMediaKeywords.instagram.some(keyword => searchKeywords.includes(keyword))) {
        window.open(`${config.links.instagram}`, "_blank");
        setLoading(false);
        setSearchTerm('');
        return;
      }

      if (socialMediaKeywords.youtube.some(keyword => searchKeywords.includes(keyword))) {
        window.open(`${config.links.youtube}`, "_blank");
        setLoading(false);
        setSearchTerm('');
        return;
      }




      if (socialMediaKeywords.imagens.some(keyword => searchKeywords.includes(keyword))) {
        window.open("https://www.youtube.com/watch?v=JoRDqdBEsP0", "_blank");
        setLoading(false);
        setSearchTerm('');
        return;
      }



      if (socialMediaKeywords.whatsapp.some(keyword => searchKeywords.includes(keyword))) {
        window.open("https://wa.me/5583982329224", "_blank");
        setLoading(false);
        setSearchTerm('');
        return;
      }


      const matchedLink = headerLinks.find((link) =>
        link.title.toLowerCase().includes(searchKeywords) ||
        link.keywords.some(keyword => searchKeywords.includes(keyword))

      );

      if (matchedLink) {
        navigate(matchedLink.path);
        // setSearchOpen(false);
        setLoading(false);
        setSearchTerm('');
        return;
      }



      try {


        const wikipediaSummary = await searchWikipedia(searchTerm);
        setDialogContent(wikipediaSummary || "Nenhum resultado encontrado. Tente uma nova busca.");
        setShowDialog(true);
        setLoading(false);

      } catch (error) {
        console.error("Erro ao buscar informação:", error);

        setDialogContent("Não foi possível buscar a informação. Tente novamente mais tarde.");
        setShowDialog(true);
        setLoading(false);
      }

    }, 500);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const fetchWikipediaSummary = async (title) => {
    const url = `https://pt.wikipedia.org/api/rest_v1/page/summary/${encodeURIComponent(title)}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.extract) {
        const lowerCaseExtract = data.extract.toLowerCase();
        if (lowerCaseExtract.includes(cidade) || lowerCaseExtract.includes(cidade)) {
          return data.extract;
        } else {
          return "Nenhuma informação relevante encontrada. Tente outra consulta.";
        }
      } else {
        return "Nenhuma informação relevante encontrada. Tente outra consulta.";
      }
    } catch (error) {
      console.error("Erro ao buscar dados na Wikipedia:", error);
      return "Ocorreu um erro ao buscar informações.";
    }
  };

  const searchWikipedia = async (query) => {
    const url = `https://pt.wikipedia.org/w/api.php?action=query&list=search&srsearch=${encodeURIComponent(query)}&format=json&origin=*`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.query.search && data.query.search.length > 0) {

        const pageTitle = data.query.search[0].title;
        return await fetchWikipediaSummary(pageTitle);
      } else {
        return "Nenhuma informação relevante encontrada. Tente outra consulta.";
      }
    } catch (error) {
      console.error("Erro ao buscar dados na Wikipedia:", error);
      return "Ocorreu um erro ao buscar informações.";
    }
  };

  const renderSubMenuItems = (items) =>
    items.map((item, index) => (

      <MenuItem
        key={index}
        component={item.type === Link ? Link : "div"}
        to={item.type === Link ? item.props.to : undefined}
        onClick={() => {
          closeSubMenu();
          setDrawerOpen(false);
        }}
        sx={{ pl: 4, color: isMobile ? 'white' : '' }}
      >
        {typeof item === 'string' ? item : item.props.children}
      </MenuItem>
    ));

  const prefeitura = [
    {
      title: "Gestão Municipal",
      icon: AdminPanelSettingsIcon,
      items: [
        <Link to="/prefeitura/prefeito" className="dropdown-item">Prefeito</Link>,
        <Link to="/prefeitura/viceprefeito" className="dropdown-item">Vice-Prefeito</Link>,
        <Link to="/secretarios" className="dropdown-item">Secretários </Link>
      ],
    },
    {
      title: "Prefeitura",
      icon: HomeIcon,
      items: [
        <Link to="/historia" className="dropdown-item">História</Link>,
        <Link to="/app/perguntas" className="dropdown-item">Perguntas</Link>,
        <Link to="/galeria" className="dropdown-item">Galeria</Link>,
        <Link to="/saladoempreendedor" className="dropdown-item">Sala do Empreendedor</Link>,
      ],
    },
    {
      title: "Documentos",
      icon: AccountBalanceIcon,
      items: [
        <Link to={config?.links?.licitacoes || "/arquivos/licitacoes"} className="dropdown-item">Licitações</Link>,
        <Link to="/arquivos/contratos" className="dropdown-item">Contratos</Link>,
        <Link to="/legislacao/portarias" className="dropdown-item">Portarias</Link>,
        <Link to="/legislacao/diariosoficial" className="dropdown-item">Diário Oficial</Link>,
        <Link to="/arquivos/farmaciabasica" className="dropdown-item">Estoque da Farmácia Básica</Link>,
        <Link to="/arquivos/frota" className="dropdown-item">Frota Municipal</Link>,
        <Link to="/rh/cc" className="dropdown-item">Contra-Cheque</Link>,
        <Link to="/rh/documentos" className="dropdown-item">Documentos Recursos Humanos</Link>,
        <Link to="/rh/fichafuncional" className="dropdown-item">Ficha Funcional</Link>,
        <Link to="/processo_seletivo" className="dropdown-item">Processo Seletivo</Link>,
      ],
    },
    {
      title: "Legislação",
      icon: GavelIcon,
      items: [
        <Link to="/legislacao/decretos" className="dropdown-item">Decretos</Link>,
        <Link to="/legislacao/leismunicipais" className="dropdown-item">Leis Municipais</Link>,
        <Link to="/legislacao/leiorganica" className="dropdown-item">Lei Orgânica</Link>,
        <Link to="/legislacao/codigotributario" className="dropdown-item">Código Tributário</Link>,
        <Link to="/legislacao/controleinterno" className="dropdown-item">Controle Interno</Link>,
        <Link to="/legislacao/aldir_blanc" className="dropdown-item">Lei Aldir Blanc</Link>,
        <Link to="/leipaulogustavo" className="dropdown-item">Lei Paulo Gustavo</Link>,
      ],
    },
    {
      title: "Planejamentos",
      icon: DescriptionIcon,
      items: [
        <Link to="/arquivos/ldo" className="dropdown-item">LDO (Lei de Diretrizes Orçamentárias)</Link>,
        <Link to="/arquivos/loa" className="dropdown-item">LOA (Lei Orçamentária Anual)</Link>,
        <Link to="/arquivos/pca" className="dropdown-item">PCA (Prestação de Contas Anual)</Link>,
        <Link to="/arquivos/rgf" className="dropdown-item">RGF (Relatório de Gestão Fiscal)</Link>,
        <Link to="/arquivos/rreo" className="dropdown-item">RREO (Relatório Resumido de Execução Orçamentária)</Link>,
      ],
    },
    {
      title: "Serviços",
      icon: BusinessCenterIcon,
      items: [
        <Link to="/app/iptu" className="dropdown-item">IPTU</Link>,
        <Link to="/app/notasfiscais" className="dropdown-item">Nota Fiscal Eletrônica</Link>,
        <Link to="/prefeitura/carta-de-servico" className="dropdown-item">Carta de Serviço</Link>,
        <Link to="/ouvidoria" className="dropdown-item">Ouvidoria</Link>,
        <Link to="/e-sic" className="dropdown-item">E-SIC (Acesso à Informação)</Link>
      ],
    },
  ];


  const camaraMunicipal = [{
    title: 'Vereadores',
    icon: GroupIcon,
    items: vereadores.map((vereador) => (
      <Link
        key={vereador.id}
        to={`/vereadores/${vereador.id}`}
        className="dropdown-item"
      >
        {vereador.nome}
      </Link>
    )),
  },
  {
    title: 'A Câmara',
    icon: HomeIcon,
    items: [
      <Link to="/e-sic" className="dropdown-item">E-SIC</Link>,
      <Link to="/historia" className="dropdown-item">História</Link>,
      <Link to="/ouvidoria" className="dropdown-item">Ouvidoria</Link>,
      <Link to="/galeria" className="dropdown-item">Galeria</Link>,
      <Link to="/mesadiretora" className="dropdown-item">Mesa Diretora</Link>,
      <Link to="/app/perguntas" className="dropdown-item">Perguntas Frequentes</Link>
    ],
  },
  {
    title: 'Documentos Oficiais',
    icon: ArticleIcon,
    items: [
      <Link to="/arquivos/contratos" className="dropdown-item">Contratos</Link>,
      <Link to="/legislacao/decretos" className="dropdown-item">Decretos</Link>,
      <Link to="/arquivos/editais" className="dropdown-item">Editais</Link>,
      <Link to="/arquivos/semanario" className="dropdown-item">Semanário</Link>,
      <Link to="/arquivos/frota" className="dropdown-item">Frota Oficial</Link>,
      <Link to="/arquivos/quadro" className="dropdown-item">Quadro Funcional</Link>,
    ],
  },
  {
    title: 'Legislação',
    icon: GavelIcon,
    items: [
      <Link to="/legislacao/diariosoficial" className="dropdown-item">Diário Oficial</Link>,
      <Link to="/legislacao/leismunicipais" className="dropdown-item">Leis Municipais</Link>,
      <Link to="/legislacao/projetodelei" className="dropdown-item">Projetos de Lei</Link>,
      <Link to="/legislacao/requerimentos" className="dropdown-item">Requerimentos</Link>,
      <Link to="/legislacao/pedidos_info" className="dropdown-item">Pedidos de Informação</Link>,
      <Link to="/legislacao/mocoes" className="dropdown-item">Moções</Link>,
      <Link to="/legislacao/leiorganica" className="dropdown-item">Lei Orgânica</Link>,
      <Link to="/legislacao/indicacoes" className="dropdown-item">Indicações</Link>,
      <Link to="/legislacao/resolucoes" className="dropdown-item">Resoluções</Link>,
      <Link to="/legislacao/pareceres" className="dropdown-item">Pareceres Jurídicos</Link>,
      <Link to="/legislacao/atas" className="dropdown-item">Atas</Link>,
      <Link to="/legislacao/portarias" className="dropdown-item">Portarias</Link>,
    ],
  },
  {
    title: 'Planejamento e Finanças',
    icon: BalanceIcon,
    items: [
      <Link to="/arquivos/ldo" className="dropdown-item">LDO - Lei de Diretrizes Orçamentárias</Link>,
      <Link to="/arquivos/loa" className="dropdown-item">LOA - Lei Orçamentária Anual</Link>,
      <Link to="/arquivos/pca" className="dropdown-item">PCA - Prestação de Contas Anual</Link>,
    ],
  },
  ];

  const renderDrawerMenu = () => (
    <Box
      role="presentation"
      sx={{ width: 250, background: config.background, height: '100%' }}
    >
      <List>
        <Link to={'/'} onClick={() => setDrawerOpen(false)}>
          <ListItem button >
            <ListItemIcon>
              <HomeIcon sx={{ color: 'white', textDecoration: 'none' }} />
            </ListItemIcon>
            <ListItemText primary="Pagina Inicial" sx={{ color: 'white', textDecoration: 'none' }} />
          </ListItem>
        </Link>


        <Divider />

        {camara ? camaraMunicipal.map((menu, index) => (
          <React.Fragment key={index}>
            <ListItem button onClick={() => toggleSubMenu(null, menu.title.toLowerCase())}>
              <ListItemIcon>
                <menu.icon sx={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary={menu.title} sx={{ color: 'white' }} />
              <ExpandMoreIcon sx={{ color: 'white' }} />
            </ListItem>
            <Collapse
              in={subMenuOpen[menu.title.toLowerCase()]}
              timeout={150}
              unmountOnExit
            >
              {renderSubMenuItems(menu.items)}
            </Collapse>
          </React.Fragment>
        )) : prefeitura.map((menu, index) => (
          <React.Fragment key={index}>
            <ListItem button onClick={() => toggleSubMenu(null, menu.title.toLowerCase())}>
              <ListItemIcon>
                <menu.icon sx={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary={menu.title} sx={{ color: 'white' }} />
              <ExpandMoreIcon sx={{ color: 'white' }} />
            </ListItem>
            <Collapse
              in={subMenuOpen[menu.title.toLowerCase()]}
              timeout={150}
              unmountOnExit
            >
              {renderSubMenuItems(menu.items)}
            </Collapse>
          </React.Fragment>
        ))}

        {config.destaque && config.destaque.active && (
          <Link to={config.destaque.link} onClick={() => setDrawerOpen(false)}>
            <ListItem button>
              <ListItemIcon>
                {React.createElement(MuiIcons[config.destaque.icon], { sx: { color: 'white' } })}
              </ListItemIcon>
              <ListItemText primary={config.destaque.name} sx={{ color: 'white' }} />
            </ListItem>
          </Link>
        )}

        {config.destaque2 && config.destaque2.active && (
          <Link to={config.destaque2.link} onClick={() => setDrawerOpen(false)}>
            <ListItem button>
              <ListItemIcon>
                {React.createElement(MuiIcons[config.destaque2.icon], { sx: { color: 'white' } })}
              </ListItemIcon>
              <ListItemText primary={config.destaque2.name} sx={{ color: 'white' }} />
            </ListItem>
          </Link>
        )}

        {config.destaque3 && config.destaque3.active && (
          <Link to={config.destaque3.link} onClick={() => setDrawerOpen(false)}>
            <ListItem button>
              <ListItemIcon>
                {React.createElement(MuiIcons[config.destaque3.icon], { sx: { color: 'white' } })}
              </ListItemIcon>
              <ListItemText primary={config.destaque3.name} sx={{ color: 'white' }} />
            </ListItem>
          </Link>
        )}
      </List>
    </Box>
  );


  const handleSearchToggle = () => {
    setSearchOpen(!searchOpen);
    if (searchOpen) {
      setSearchTerm('');
    }
  };






  return (

    <>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>
          Resultado da Pesquisa
          <IconButton
            aria-label="close"
            onClick={() => setShowDialog(false)}
            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>{dialogContent}</Typography>
        </DialogContent>
      </Dialog>


      <AppBar position="sticky" sx={{ background: config.background }}>
        <Toolbar sx={{ display: 'flex', alignItems: 'center' }}>
          {isMobile && (
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon sx={{ fontSize: 35 }} />
            </IconButton>
          )}

          <Link to={'/'}>
            <Logo
              src={config?.logo || 'https://kenekt-cdn.imgix.net/tvz5ih9ce0gbj652p4i5q2xpbq8u?ixlib=rails-4.3.1'}
              alt="Logo"
              sx={{
                marginBottom: isMobile ? 2 : 1,
                marginTop: isMobile ? 2 : 1,
                marginLeft: isMobile ? 2 : 0,
                maxWidth: config?.logoSize + 'px'
              }}
            />
          </Link>


          {!isMobile && (
            <>
              <Link to={'/'}>
                <Button
                  color="inherit"
                  startIcon={<HomeIcon />}
                  sx={{
                    marginLeft: 5,
                    fontWeight: 'bold',
                    color: '#ffffff',
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: fontSize,
                    textTransform: 'none',
                    textDecoration: 'none',
                    '&:hover': { color: '#ffffff' },
                  }}
                >
                  Início
                </Button>
              </Link>



              {camara ? camaraMunicipal.map((menu, index) => (
                <React.Fragment key={index}>
                  <Button
                    color="inherit"
                    startIcon={<menu.icon />}
                    endIcon={<ExpandMoreIcon />}
                    onClick={(event) => toggleSubMenu(event, menu.title.toLowerCase())}
                    sx={{
                      fontWeight: 'bold',
                      marginLeft: 0.2,
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: fontSize,
                      textTransform: 'none',
                      '&:hover': { color: '#ffffff' },
                    }}
                  >
                    {menu.title}
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(subMenuOpen[menu.title.toLowerCase()]) && anchorEl}
                    onClose={closeSubMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 100 }}
                  >
                    {renderSubMenuItems(menu.items)}
                  </Menu>
                </React.Fragment>
              )) : prefeitura.map((menu, index) => (
                <React.Fragment key={index}>
                  <Button
                    color="inherit"
                    startIcon={<menu.icon />}
                    endIcon={<ExpandMoreIcon />}
                    onClick={(event) => toggleSubMenu(event, menu.title.toLowerCase())}
                    sx={{
                      fontWeight: 'bold',
                      marginLeft: 0.2,
                      fontFamily: 'Roboto, sans-serif',
                      fontSize: fontSize,
                      textTransform: 'none',
                      '&:hover': { color: '#ffffff' },
                    }}
                  >
                    {menu.title}
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(subMenuOpen[menu.title.toLowerCase()]) && anchorEl}
                    onClose={closeSubMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 100 }}
                  >
                    {renderSubMenuItems(menu.items)}
                  </Menu>
                </React.Fragment>
              ))}

              {config.destaque.active && !config.destaque2.active && !config.destaque3.active ? (
                <Button
                  color="inherit"
                  component={Link}
                  to={config.destaque.link}
                  startIcon={React.createElement(MuiIcons[config.destaque.icon])}
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                >
                  {config.destaque.name}
                </Button>
              ) : (
                (config.destaque.active || config.destaque2.active || config.destaque3.active) && (
                  <Button
                    color="inherit"
                    startIcon={<OpenInNewIcon />}
                    endIcon={<ExpandMoreIcon />}
                    onClick={(event) => setImportantLinksAnchorEl(event.currentTarget)}
                    sx={{ fontWeight: 'bold', textTransform: 'none' }}
                  >
                    Links Importantes
                  </Button>
                )
              )}

              <Menu
                anchorEl={importantLinksAnchorEl}
                open={Boolean(importantLinksAnchorEl)}
                onClose={closeImportantLinksMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {config.destaque.active && (
                  <MenuItem component={Link} to={config.destaque.link} onClick={closeImportantLinksMenu}>
                    {/* <ListItemIcon>
                      {React.createElement(MuiIcons[config.destaque.icon])}
                    </ListItemIcon> */}
                    {config.destaque.name}
                  </MenuItem>
                )}
                {config.destaque2.active && (
                  <MenuItem component={Link} to={config.destaque2.link} onClick={closeImportantLinksMenu}>
                    {/* <ListItemIcon>
                      {React.createElement(MuiIcons[config.destaque2.icon])}
                    </ListItemIcon> */}
                    {config.destaque2.name}
                  </MenuItem>
                )}
                {config.destaque3.active && (
                  <MenuItem component={Link} to={config.destaque3.link} onClick={closeImportantLinksMenu}>
                    {/* <ListItemIcon>
                      {React.createElement(MuiIcons[config.destaque3.icon])}
                    </ListItemIcon> */}
                    {config.destaque3.name}
                  </MenuItem>
                )}
              </Menu>
            </>
          )}

          <Box sx={{ flexGrow: 1 }} />

          {!isMobile && (
            <Collapse in={searchOpen} timeout="auto" orientation="horizontal" sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
              <Box
                component="form"
                onSubmit={handleSearchSubmit}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <TextField
                  variant="outlined"
                  placeholder="Pesquise aqui..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          type="submit"
                          edge="end"
                          sx={{
                            padding: '3px',
                            color: '#333',
                          }}
                        >
                          {loading ? <CircularProgress size={20} /> : <SearchIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: '230px',
                    backgroundColor: '#f1f1f1',
                    borderRadius: 1,
                    '& .MuiOutlinedInput-root': {
                      paddingRight: '20px',
                      paddingLeft: '8px',
                    },
                  }}
                />
                <IconButton
                  onClick={handleSearchToggle}
                  sx={{
                    backgroundColor: 'white',
                    '&:hover': {
                      backgroundColor: '#1d1d1d',
                      color: 'white',
                    },
                    marginLeft: 1,
                    padding: '6px',
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Collapse>
          )}

          {!searchOpen && (
            <IconButton
              onClick={handleSearchToggle}
              sx={{
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: '#1d1d1d',
                  color: 'white',
                },
                marginLeft: 'auto',
              }}
            >
              <SearchIcon />
            </IconButton>
          )}
        </Toolbar>

        {isMobile && (
          <Collapse in={searchOpen} timeout="auto" sx={{ marginTop: 1, display: 'flex', justifyContent: 'center' }}>
            <Box
              component="form"
              onSubmit={handleSearchSubmit}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '90%',
                margin: '0 auto',
              }}
            >
              <IconButton
                onClick={handleSearchToggle}
                sx={{
                  backgroundColor: 'white',
                  '&:hover': {
                    backgroundColor: '#1d1d1d',
                    color: 'white',
                  },
                  marginRight: 1,
                  padding: '6px',
                }}
              >
                <CloseIcon />
              </IconButton>

              <TextField
                variant="outlined"
                placeholder="Pesquise aqui..."
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        type="submit"
                        edge="end"
                        sx={{
                          padding: '3px',
                          color: '#333',
                        }}
                      >
                        {loading ? <CircularProgress size={20} /> : <SearchIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: '80%',
                  backgroundColor: '#f1f1f1',
                  borderRadius: 1,
                  '& .MuiOutlinedInput-root': {
                    paddingRight: '20px',
                    paddingLeft: '8px',
                  },
                }}
              />
            </Box>
          </Collapse>
        )}

        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          {renderDrawerMenu()}
        </Drawer>
      </AppBar>




    </>




  );
}

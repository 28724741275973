import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { getDatabase, ref, get } from "firebase/database";
import { database, KEY_REF } from "./adm/admin/firebaseConfig";
import SecretaryPage from "./utils/SecretaryCard";
import { Container, Skeleton, Typography } from "@mui/material";

export default function ViewGestao() {
  const [gestor, setGestor] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  const fetchGestao = useCallback(async () => {
    try {
      const gestaoRef = ref(database, `gestao`);
      const snapshot = await get(gestaoRef);

      if (snapshot.exists()) {
        const data = snapshot.val();
        const gestoresArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));

        let gestorFiltrado = null;

        if (id === "prefeito") {
          gestorFiltrado = gestoresArray.find(
            (gestor) => gestor.cargo.toLowerCase() === "prefeito"
          );
        } else if (id === "viceprefeito") {
          gestorFiltrado = gestoresArray.find(
            (gestor) => gestor.cargo.toLowerCase() === "vice-prefeito"
          );
        } else {
          gestorFiltrado = gestoresArray.find((gestor) => gestor.id === id);
        }

        setGestor(gestorFiltrado || null);
      } else {
        console.warn("Nenhum dado encontrado para a gestão municipal.");
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchGestao();
  }, [fetchGestao]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "60vh" }}>
      {isLoading ? (
        <Skeleton variant="rectangular" width={700} height={300} sx={{ borderRadius: 2 }} />
      ) : gestor ? (
        <SecretaryPage {...gestor} />
      ) : (
        <Typography variant="h6" color="textSecondary">
          Gestão não encontrada
        </Typography>
      )}
    </Container>
  );
}
